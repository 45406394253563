export const countries = [
  { name: 'United States 🇺🇸', code: 'US' },
  { name: 'Australia 🇦🇺', code: 'AU' },
  { name: 'Austria 🇦🇹', code: 'AT' },
  { name: 'Belgium 🇧🇪', code: 'BE' },
  { name: 'Canada 🇨🇦', code: 'CA' },
  { name: 'Denmark 🇩🇰', code: 'DK' },
  { name: 'Estonia 🇪🇪', code: 'EE' },
  { name: 'Finland 🇫🇮', code: 'FI' },
  { name: 'France 🇫🇷', code: 'FR' },
  { name: 'Germany 🇩🇪', code: 'DE' },
  { name: 'Greece 🇬🇷', code: 'GR' },
  { name: 'Hong Kong 🇭🇰', code: 'HK' },
  { name: 'Ireland 🇮🇪', code: 'IE' },
  { name: 'Italy 🇮🇹', code: 'IT' },
  { name: 'Japan 🇯🇵', code: 'JP' },
  { name: 'Latvia 🇱🇻', code: 'LV' },
  { name: 'Lithuania 🇱🇹', code: 'LT' },
  { name: 'Luxembourg 🇱🇺', code: 'LU' },
  { name: 'Mexico 🇲🇽', code: 'MX' },
  { name: 'Netherlands 🇳🇱', code: 'NL' },
  { name: 'New Zealand 🇳🇿', code: 'NZ' },
  { name: 'Norway 🇳🇴', code: 'NO' },
  { name: 'Poland 🇵🇱', code: 'PL' },
  { name: 'Portugal 🇵🇹', code: 'PT' },
  { name: 'Singapore 🇸🇬', code: 'SG' },
  { name: 'Slovakia 🇸🇰', code: 'SK' },
  { name: 'Slovenia 🇸🇮', code: 'SI' },
  { name: 'Spain 🇪🇸', code: 'ES' },
  { name: 'Sweden 🇸🇪', code: 'SE' },
  { name: 'Switzerland 🇨🇭', code: 'CH' },
  { name: 'United Kingdom 🇬🇧', code: 'GB' }
];

export const states = [
  '',
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];