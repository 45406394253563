import React, { useState, useEffect, useRef } from 'react';
import './UpgradeSubscriptionModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import UseWindowDimensions from '../Utils/UseWindowDimensions';
import SubscriptionCard from '../SubscriptionCard/SubscriptionCard';
import { ReactSVG } from 'react-svg';
import getDomainName from '../Utils/DomainName';

export default function UpgradeSubscriptionModal(props) {
  const [showingInfo, setShowingInfo] = useState('');
  const [businessSubscription24M, setBusinessSubscription24M] = useState({});
  const [businessSubscripton24A, setBusinessSubscripton24A] = useState({});
  const [residentialSubscripton12M, setResidentialSubscripton12M] = useState({});
  const [residentialSubscripton12A, setResidentialSubscripton12A] = useState({});
  const [residentialSubscripton24M, setResidentialSubscripton24M] = useState({});
  const [residentialSubscripton24A, setResidentialSubscripton24A] = useState({});
  const [chosenSubscription, setChosenSubscription] = useState(false);
  const [subscription, setSubscription] = useState({})
  const { width } = UseWindowDimensions();
  const infoButton = useRef(null);

  useEffect(()=>{
    fetchSubscriptions()
  }, [])

  function fetchSubscriptions() {
    let domainName = getDomainName(window.location.hostname);
    let url = `https://${domainName}/ecommerce/api/get_plans`;
    fetch(url,{
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      if(props.user.type === 1) {
        setBusinessSubscription24M(data.business['24'].monthly);
        setBusinessSubscripton24A(data.business['24'].yearly);
      } else {
        setResidentialSubscripton12M(data.residential['12'].monthly);
        setResidentialSubscripton12A(data.residential['12'].yearly)
        setResidentialSubscripton24M(data.residential['24'].monthly)
        setResidentialSubscripton24A(data.residential['24'].yearly)
      }
    })
  }

  function addSubscription() {   
    let house = props.house
    let hubs = props.hubs.map(hub=>({hub_id: hub.uuid,serial_no: hub.serial_number,camera_count: hub.camera_qty,is_deleted: hub.is_deleted}));
    let data = {
      house: {
        house_id: house.uuid.replace(/-/g, ''),
        user_email: localStorage.email,
        name: house.name,
        address: {
          address1: house.address_1,
          address2: house.address_2,
          city: house.city,
          state: house.state,
          zipcode: house.zipcode
        },
        total_hubs: house.total_hubs,
        total_cameras: house.total_cameras,
        hubs: hubs
      },
      user_email: localStorage.email,
      term: subscription.hub.metadata.term[0].toUpperCase(),
      monitoring: subscription.hub.metadata.monitoring.toUpperCase(),
      plan_type: subscription.hub.metadata.type[0].toUpperCase()
    }
    props.addSubscription(data);
  }

  function chooseSubscription(data, code) {
    if(code === chosenSubscription) {
      setChosenSubscription(false);
      return;
    }
    setSubscription(data)
    setChosenSubscription(code);
  }

  function renderCheck() {
    return (
      <ReactSVG beforeInjection={svg => {
        svg.classList.add('check')
        }}
        src={require("../assets/check_icon.svg")}
      /> 
    )
  }
  return (
    <div className="upgrade-modal-container">
      <div className="upgrade-modal-content">
        <div className="close-container">
          <img src={require('../assets/close.png')} alt="" className="close" onClick={()=>{props.close(false)}}/>
        </div>
        <div className="modal-header">
          <div className="back-container">
            <div className="back" onClick={()=>{props.close(false)}}></div>
          </div>
          <div className="title" style={{ fontSize: '1.5em'}}>Choose Subscription</div>
        </div>
        <div className="subscriptions-container">
            {
              props.user.type === 1 && businessSubscription24M.hub ? (
                <div className="subscriptions">
                    <SubscriptionCard house={props.house} subscription={businessSubscription24M} paymentCycle={'monthly'} monitoring={'24'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'b24m'} code='b24m'/>
                    <SubscriptionCard house={props.house} subscription={businessSubscripton24A} paymentCycle={'yearly'} monitoring={'24'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'b24y'} code='b24y'/>
                </div>
              ) : (
                residentialSubscripton24A.hub && (
                    <div className="subscriptions">
                      <SubscriptionCard house={props.house} subscription={residentialSubscripton12M} paymentCycle={'monthly'} monitoring={'12'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'r12m'} code='r12m'/>
                      <SubscriptionCard house={props.house} subscription={residentialSubscripton12A} paymentCycle={'yearly'} monitoring={'12'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'r12y'} code='r12y'/>
                      <SubscriptionCard house={props.house} subscription={residentialSubscripton24M} paymentCycle={'monthly'} monitoring={'24'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'r24m'} code='r24m'/>
                      <SubscriptionCard house={props.house} subscription={residentialSubscripton24A} paymentCycle={'yearly'} monitoring={'24'} chooseSubscription={chooseSubscription} chosenSubscription={chosenSubscription === 'r24y'} code='r24y'/>
                    </div>
                  )
                )
              }
            <div className="plan-desc-container">
              <div className="plan-desc-title">All plans include:</div>
              <div className="details-container">
                <div className="plan-desc-detail">{renderCheck()} Motion alerts</div>
                <div className="plan-desc-detail">{renderCheck()} AI crime detection</div>
                <div className="plan-desc-detail">{renderCheck()} Emergency Contact</div>
                <div className="plan-desc-detail">{renderCheck()} Live view</div>
                <div className="plan-desc-detail">{renderCheck()} 2-way communication</div>
              </div>
            </div>
          </div>
        <div className="update-info-button-container">
          <button className="update-info-button" disabled={chosenSubscription === false} style={{backgroundColor: (chosenSubscription !== false) ? '#3F3250' : 'rgb(170, 170, 170)'}} onClick={addSubscription}>Upgrade!</button>
        </div>
      </div>
    </div>
  )
}