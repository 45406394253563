import React from 'react';
import Overlay from '../Overlay/Overlay';
import './Header.scss';

export default function Header(props) {
  return (
    <div className="header-container">
      <Overlay click={props.click}/>
    </div>
  )
}