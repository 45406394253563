import React from 'react';
import Billing from '../Billing/Billing';
import {StripeProvider} from 'react-stripe-elements';
import {Elements} from 'react-stripe-elements';
export default function BillingParent(props) {
  return (
    <StripeProvider apiKey="pk_test_BJfBpWn9WeaDA018u3Iajh0h">
      <Elements>
        <Billing />
      </Elements>
    </StripeProvider>
  )
}