import React, { useState, useEffect } from 'react';
import './Account.scss';
import Footer from '../Footer/Footer';
import AccountInfoModal from '../AccountInfoModal/AccountInfoModal';
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getDomainName from '../Utils/DomainName';
import { getCustomerData } from '../DataCache/DataCache';

export default function Account(props) {
  const [isUpdatingInfo, setIsUpdatingInfo] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    fetchCustomer();
  }, [])

  function triggerModal(open, type) {
    switch (type) {
      case 'password':
        setIsUpdatingPassword(open);
        break;
      case 'accountInfo':
        setIsUpdatingInfo(open);
        break;
      default:
        break;
    }
  }

  function fetchCustomer() {
    getCustomerData({
      email: localStorage.email,
      onSuccess: customerData => {
        setCustomer(customerData);
      },
      onFail: _ => {
        window.localStorage.clear();
        props.history.push('/login');
      }
    });
  }

  function updateCustomerData(data) {
    let domainName = getDomainName(window.location.hostname);
    const url = `https://${domainName}/mobile/app/ecustomer/v1/api/update_user_details`
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
      body: JSON.stringify(data)
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      setCustomer(data.data);
    })
  }

  function changePasswordToast() {
    toast('Password Changed Successfully')
    setIsUpdatingPassword(false);
  }

  return (
    <div className="account-page-container">
      <div className="content-container">
      <div className="title">Account</div>
        <div className="user-info-content-container">
        <div className="name">{(customer.first_name !== undefined ? customer.first_name + ' ' + customer.last_name : '')}</div>
        <div className="info-detail-1">Email: {customer.email_address || ''}</div>
        <div className="info-detail">Phone: {customer.mobile_phone}</div>
          <div className="update-info-button" onClick={()=>{setIsUpdatingInfo(true);}}>Update Info</div>
        </div>
        <div className="password-content-container">
          <div className="info-detail">Password: *******</div>
          <div className="change-password-button" onClick={()=>{setIsUpdatingPassword(true);}}>Change Password</div>
        </div>
        <Footer/>
      </div>
      {
        isUpdatingInfo && !isUpdatingPassword && (
          <AccountInfoModal updateCustomerData={updateCustomerData} account={customer} close={triggerModal} />
        )
      }
      {
        isUpdatingPassword && !isUpdatingInfo && (
          <ChangePasswordModal successToast={changePasswordToast} account={customer} close={triggerModal} />
        )
      }
    </div>
  )
}