import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import './CardInfo.scss';
export default function CardInfo(props) {
  return (
    <div className="card-container">
      <div className="left-card">
        <div className="card-info-detail">**** **** **** {props.card.last4 || 1234}</div>
          {props.card.default_source ? <div className="default">*PRIMARY</div> : ''}
        <div className="card-info-detail">{props.card.name || 'No Name'}</div>
      </div>
      <div className="right-card">
        <div className="edit" onClick={props.editCard}><FontAwesomeIcon icon={faPencilAlt} className="edit-icon"/></div>
        <div className="expire_date">{props.card.exp_month}/{props.card.exp_year && props.card.exp_year.toString().substr(-2)}</div>
      </div>
    </div>
    );
}