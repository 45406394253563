import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.scss';
import UseWindowDimensions from '../Utils/UseWindowDimensions';
import getDomainName from '../Utils/DomainName';
import clearCache from '../DataCache/DataCache';
function Sidebar (props){
  let sideBarClasses = 'sidebar-container';
  if (props.show) {
    sideBarClasses = 'sidebar-container open';
  }
  const [accountLinkColor, setAccountLinkColor] = useState('')
  const [billingLinkColor, setBillingLinkColor] = useState('')
  const [homeAndDevicesLinkColor, setHomeAndDevicesColor] = useState('')
  const [currPath, setCurrPath] = useState('account');
  const { width } = UseWindowDimensions();
  

  useEffect(_=>{
    let cachedPageName = window.localStorage.getItem("defaultPage");
    if(cachedPageName == null){
      window.localStorage.setItem("defaultPage", "account");
    }
    setCurrPath(window.localStorage.getItem("defaultPage"));
  }, [])
  
  useEffect(()=>{
    if(currPath!= null){
      window.localStorage.setItem('defaultPage', currPath);
      setLinkColorState(currPath);
    }
  }, [currPath]);

  

  function clearLinkColors() {
    setAccountLinkColor('');
    setBillingLinkColor('');
    setHomeAndDevicesColor('')
  }

  function setLinkColorState(page) {
    switch (page) {
      case 'account':
        clearLinkColors();
        setAccountLinkColor('white');
        break;
      case 'billing':
        clearLinkColors();
        setBillingLinkColor('white')
        break;
      case 'home-and-devices':
        clearLinkColors();
        setHomeAndDevicesColor('white')
        break;
      default:
        clearLinkColors();
        setAccountLinkColor('white');
        break;
    }
    props.click();
  }

  function logOut() {
    let domainName = getDomainName(window.location.hostname);
    let url = `https://${domainName}/mobile/app/ecustomer/v1/api/logout`
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      setCurrPath('account');
      clearCache();
    })
  }

  return (
    <div className={sideBarClasses}>
      {
        width < 768 ? (
          <div className="header">
            <div className="close-container">
              <div className="close" onClick={props.click} />
            </div>
          </div>
        ) : (
          <div className="header-desktop">
            <Link to="/" className="link" onClick={()=>{setCurrPath('account')}}>  
              <img className="sidebar-logo-img" src={require("../assets/Logo_graphic@2x.png")} />
            </Link>
          </div>
        )
      }
      <div className="links">
        <Link to="/" style={{ color: accountLinkColor}} className="link" onClick={()=>{setCurrPath('account')}}>
          Account
        </Link>
        <Link to="/billing" style={{ color: billingLinkColor}} className="link" onClick={()=>{setCurrPath('billing')}}>
          Billing
        </Link>
        <Link to="/home-and-devices"  style={{ color: homeAndDevicesLinkColor}} className="link" onClick={()=>{setCurrPath('home-and-devices')}}>
          Home & Devices
        </Link>
      </div> 
      <div className="sidebar-footer">
        <Link to="/login" style={{textDecoration: 'none'}}>
          <div className="logout" onClick={logOut}>Log Out</div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
