import getDomainName from '../Utils/DomainName';

export let houseData = [];

export let customerData = {};

export let stripeCustomer = {};

export let subscriptionData = {};

export default function clearCache(){
  subscriptionData = {};
  stripeCustomer = {};
  customerData = {};
  houseData = [];
  window.localStorage.clear();
}

export async function getSubscriptionData({houseData, onSuccess, onFail}) {
  if(subscriptionData[houseData.house.uuid]) {
    const house = subscriptionData[houseData.house.uuid];
    onSuccess && onSuccess(house);
    return;
  }
  
  let domainName = getDomainName(window.location.hostname);
  let url = `https://${domainName}/ecommerce/api/admin_dashboard_v2/subscription`
  let hubs = houseData.hubs ? houseData.hubs.map(hub=>({hub_id: hub.uuid,serial_no: hub.serial_number,camera_count: hub.camera_qty,is_deleted: hub.is_deleted})) : []
  let body = {
    house_id: houseData.house.uuid.replace(/-/g, ''),
    user_email: localStorage.email,
    name: houseData.house.name,
    address: {
      address1: houseData.house.address_1,
      address2: houseData.house.address_2,
      city: houseData.house.city,
      state: houseData.house.state,
      zipcode: houseData.house.zipcode
    },
    total_hubs: houseData.house.total_hubs,
    total_cameras: houseData.house.total_cameras,
    hubs: hubs
  }
  fetch(url, {
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.session_key
    }),
    method: 'POST',
    body: JSON.stringify(body)
  })
  .then(response => {
    return response.json()
  })
  .then(data=>{
    if(data) {
      subscriptionData[houseData.house.uuid] = data
      onSuccess && onSuccess(data);
    } else {
      console.warn('fetch_from_server failed! not data', data);
      onFail && onFail();
    }
  })
}
export async function getHouseData({ds_customer_id, onSuccess, onFail}) {
  if(houseData.length > 0) {
    onSuccess && onSuccess(houseData);
    return;
  }
  let domainName = getDomainName(window.location.hostname);
  let url = `https://${domainName}/mobile/app/ecustomer/v1/api/fetch_house_hubs?ds_customer_id=${ds_customer_id}`
  fetch(url, {
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.session_key
    }),
  })
  .then(response=>{
    return response.json();
  })
  .then(content=>{
    if(content.data){
      houseData = content.data
      onSuccess && onSuccess(content.data);
    } else {
      onFail && onFail();
    }
  })
}

export async function getCustomerData({email, onSuccess, onFail}) {
  if(customerData.email_address) {
    onSuccess && onSuccess(customerData);
    return;
  };
  
  let domainName = getDomainName(window.location.hostname);
  const url = `https://${domainName}/mobile/app/ecustomer/v1/api/fetch_user_details?customer_email=${encodeURIComponent(email)}`
  fetch(url, {
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.session_key
    }),
  })
  .then(response=>{
    return response.json();
  })
  .then(data=>{
    if(data.status === 0) {
      onFail && onFail();
    }
    if(data.data) {
      customerData = data.data;
      onSuccess && onSuccess(customerData);
    } else {
      onFail && onFail();
    }
  })
}

export async function getStripeCustomerData({data, onSuccess, onFail}) {
  if(stripeCustomer.customer_stripe_id) {
    onSuccess && onSuccess(stripeCustomer);
    return;
  };

  let domainName = getDomainName(window.location.hostname);
  let url = `https://${domainName}/ecommerce/api/admin_dashboard_v2/subscription/fetch_customer`;
  fetch(url, {
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.session_key
    }),
    method: 'POST',
    body: JSON.stringify(data)
  })
  .then(response=>{
    return response.json();
  })
  .then(data=>{
    stripeCustomer = data;
    onSuccess(stripeCustomer);
  });
}