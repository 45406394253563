import React, { useState, useEffect } from 'react';
import UseWindowDimensions from '../Utils/UseWindowDimensions';
import './SubscriptionCard.scss';
import { ReactSVG } from 'react-svg';

export default function SubscriptionCard(props) {
  const { width } = UseWindowDimensions();
  const { subscription, paymentCycle, monitoring, code } = props
  const [bullets, setBullets] = useState([]);

  useEffect(() => {
    setInfoBullets();
  }, [])

  function setInfoBullets() {
    switch (code) {
      case 'b24m':
        setBullets(['Live guards monitoring 8 pm - 8 am', 'Guards intervene, law enforcement contacted during monitoring hours', 'Cancel anytime']);
        break;
      case 'b24y':
        setBullets(['Live guards monitoring 8 pm - 8 am', 'Guards intervene, law enforcement contacted during monitoring hours', 'Cancel anytime']);
        break;
      case 'r12m':
        setBullets(['Live guards monitoring 8 pm - 8 am', 'Guards intervene, law enforcement contacted during monitoring hours', 'Cancel anytime'])
        break;
      case 'r12y':
        setBullets(['Live guards monitoring 8 pm - 8 am', 'Guards intervene, law enforcement contacted during monitoring hours', 'Cancel anytime'])
        break;
      case 'r24m':
        setBullets(['Live guards monitoring 8 pm - 8 am', 'Guards intervene, law enforcement contacted during monitoring hours', 'Cancel anytime'])
        break;
      case 'r24y':
        setBullets(['Live guards monitoring 8 pm - 8 am', 'Guards intervene, law enforcement contacted during monitoring hours', 'Cancel anytime'])
        break;
      default:
        setBullets(['']);
        break;
    }
  }

  function renderSVG() {
    if(props.chosenSubscription) {
      if(monitoring === '24') {
        return (
          <ReactSVG beforeInjection={svg => {
            svg.classList.add('subscription-svg-24')
            }}
            src={require("../assets/247_icon_white.svg")}
          />
        )
      } else {
        return (
          <ReactSVG beforeInjection={svg => {
            svg.classList.add('subscription-svg-12')
          }}
          src={require("../assets/night_icon_white.svg")}
        />
        )
      }
    } else {
      if(monitoring === '24') {
        return (
          <ReactSVG beforeInjection={svg => {
            svg.classList.add('subscription-svg-24')
          }}
          src={require("../assets/247_icon_dark.svg")}
        />
        )
      } else {
        return (
          <ReactSVG beforeInjection={svg => {
            svg.classList.add('subscription-svg-12')
          }}
          src={require("../assets/night_icon_dark.svg")}
        />
        )
      }
    }
  }

  if(subscription.hub) {
    return (
      <div className="subscription-card-container" style={{maxWidth: width > 768 ? '45vw' : ''}}>
        <div className={`subscription-card ${subscription.status}`}>
          <div className="subscription-container card-front" onClick={(e)=>{props.chooseSubscription(subscription, code)}} style={{backgroundColor: props.chosenSubscription ? '#3F3250': '#F1F1F1', color: props.chosenSubscription ? 'white': '#222222'}}>

            {
              renderSVG()
            }
            <div className="content-container">
              <div className="subscription-title">{monitoring}hr {paymentCycle.toLowerCase() === 'yearly' ? 'annual' : 'monthly'} service</div>
              <div className="subscription-detail"><div className="price">${((subscription.hub.amount * props.house.total_hubs) + (subscription.camera.amount * props.house.total_cameras)) / 100}</div><div className="cycle">/{paymentCycle.toLowerCase().replace('ly', '')}</div></div>
              {
                !props.chosenSubscription && width < 768 && (
                  <div className="subscription-detail"><div className="point">•&nbsp;</div>{bullets[0]}</div>
                )
              }
              {
                props.chosenSubscription && width < 768 && (
                  bullets.map((bullet, key)=>{
                    return (
                        <div key={key} className="subscription-detail"><div className="point">•&nbsp;</div>{bullet}</div>
                      )
                  })
                )
              }
              {
                width > 768 && (
                  bullets.map(bullet=>{
                    return (
                        <div className="subscription-detail"><div className="point">•&nbsp;</div>{bullet}</div>
                      )
                  })
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    ''
  )
}