import React, {useState} from 'react'
import { ReactSVG } from 'react-svg'
import { Redirect } from 'react-router-dom';
import './Login.scss';
import getDomainName from '../Utils/DomainName';
import {sendData} from '../Utils/utilities'
export default function Login (props){
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPassword, setIsPassword] = useState(true);
  const [loginError, setLoginError] = useState('');
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
   const handleInputChange = cb => event => {
    cb(event.target.value)
  };

  const loginUser = (user, history) => {
    let domainName = getDomainName(window.location.hostname);
    let url = `https://${domainName}/mobile/app/ecustomer/v1/api/login`;
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(user)
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      if(!data.data) {
        setLoginError('Username or password is incorrect');
        return;
      }
      if(data.data.session_key) {
        if(!window.localStorage.session_key) {
          window.localStorage.setItem('session_key', data.data.session_key);
          window.localStorage.setItem('email', data.data.user.email_address);
        }
        setRedirectToReferrer(true);
      }
    });
  };

  function handleLogin() {
    const user = {
      email_address: email,
      password: password
    };
    loginUser(user, props.history);
  }

  const { from } = props.location.state || { from: { pathname: '/' } }
  
  if (redirectToReferrer === true) {
    return <Redirect to={from} />
  }

  const onForgetPassword = function(){
      let isValid = validateEmail(email);
      if(isValid){
        // Todo: Call API
        let domainName = getDomainName(window.location.hostname);
        let url = `https://${domainName}/mobile/app/v2/reset-password?email=${encodeURIComponent(email)}`;
        sendData(url, 'GET', null, (response)=>{
          if(response){
              setLoginError("Check email to reset password")
          }
        })
      }
      else{
        setLoginError("Please enter a valid email address");
      }
  }

const validateEmail = function() 
  {
    let pattern = /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    if (pattern.test(email))
      {
        return true;
      }
      return false;
  }

  return (
    <div className="login-page-container">
      <div className="login-page-content">
        <div className="logo-container">
          <ReactSVG src={require("../assets/red_logo.svg")} />
        </div>
        <div className="title">Login</div>
        <div className="form-container">
          <div className="email-container">
            <input autoComplete="new-password" type="hidden" type="text" value={email} placeholder="Email" onChange={handleInputChange(setEmail)} className="email-input"/>
          </div>
          <div className="password-container">
            <input autoComplete="new-password" type={isPassword ? "password" : "text"} value={password} placeholder="Password" onChange={handleInputChange(setPassword)} className="password-input"/>
            <div className="password-eye-container" onClick={()=>{setIsPassword(!isPassword)}}>
              {
                isPassword ? <ReactSVG src={require("../assets/hide_icon.svg")} /> : <ReactSVG src={require("../assets/show_icon.svg")} />
              }
            </div>
          </div>
          <div className="forgot-password" style={{cursor:"pointer"}} onClick={onForgetPassword}>Forgot password?</div>
          { loginError && <div className="login-error">{loginError}</div> }
        <div className="login-button-container">
          <div className="login-button" onClick={handleLogin}>Login</div>
        </div>
        </div>
      </div>
      </div>
  )
}