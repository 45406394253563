import React, { useState } from 'react';
import './ChangePasswordModal.scss';
import getDomainName from '../Utils/DomainName';
import clearCache from '../DataCache/DataCache';
export default function ChangePasswordModal(props) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');


  function logOut() {
    let domainName = getDomainName(window.location.hostname);
    let url = `https://${domainName}/mobile/app/ecustomer/v1/api/logout`
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      clearCache();
      window.location.href = '/login';
    })
  }

  function editPasswordApi(data) {
    let domainName = getDomainName(window.location.hostname);
    const url = `https://${domainName}/mobile/app/ecustomer/v1/api/change_password`;
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
      body: JSON.stringify(data)
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      if(!data.data) {
        setPasswordError('Wrong Current Password')
        return;
      }
      props.successToast();
      logOut();
    })
  }


  function editPassword() {
    if(!newPassword || !confirmNewPassword) {
      setPasswordError('Please complete fields');
      return;
    }
    if((newPassword === confirmNewPassword)) {
      let data = {
        customer_email: props.account.email_address,
        current_password: oldPassword,
        new_password: newPassword
      }
      editPasswordApi(data);
      return 'success';
    }
    setPasswordError('Passwords don\'t match')
  }

  return (
    <div className="modal-container">
    <div className="modal-content">
      <div className="close-container">
        <img src={require('../assets/close.png')} alt="" className="close" onClick={()=>{props.close(false, 'password')}}/>
      </div>
      <div className="change-password-modal-header">
        <div className="back-container">
          <div className="back" onClick={()=>{props.close(false, 'password')}}></div>
        </div>
        <div className="change-password-title">Change Password</div>
      </div>
      {passwordError && <div className="error" style={{color: 'red', width: '100%', textAlign: 'center'}}>{passwordError}</div>}
      <div className="inputs-container">
        <div className="input-container">
          <div className="label">Old Password</div>
          <input type="text" placeholder="" className="input" value={oldPassword} onChange={e => setOldPassword(e.target.value)}/>
        </div>
        <div className="input-container">
          <div className="label">New Password</div>
          <input type="text" placeholder="" className="input" value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
        </div>
        <div className="input-container">
          <div className="label">Confirm New Password</div>
          <input type="text" placeholder="" className="input" value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)}/>
        </div>
      </div>
      <div className="update-info-button-container">
        <div className="update-info-button" onClick={editPassword}>Update Password</div>
      </div>
    </div>
  </div>
  )
}