import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../Login/Login';
import HomeAndDevices from '../HomeAndDevices/HomeAndDevices';
import Account from '../Account/Account';
import BillingParent from '../BillingParent/BillingParent';
import NotFound from '../NotFound/NotFound';
import './MainContainer.scss';
export default function MainContainer(props){
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const authenticated = localStorage.session_key;
    setAuthenticated(authenticated);
  }, [])

  const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
      />
    );
  };

  function checkAuth() {
    if(localStorage.session_key) {
      return true;
    } 
    return false;
  }

  return (
      <div className="main-container">
        <Switch>
          <ProtectedRoute
            isAuthenticated={() => checkAuth()}
            exact
            path="/"
            component={Account}
          >
          </ProtectedRoute>
          <ProtectedRoute
            isAuthenticated={() => checkAuth()}
            exact
            path="/account"
            component={Account}
          >
          </ProtectedRoute>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute
            isAuthenticated={() => checkAuth()}
            exact
            path="/billing"
            component={BillingParent}
          >
          </ProtectedRoute>
          <ProtectedRoute
            isAuthenticated={() => checkAuth()}
            exact
            path="/home-and-devices"
            component={HomeAndDevices}
          >
          </ProtectedRoute>
          <ProtectedRoute
            isAuthenticated={() => checkAuth()}
            component={NotFound}
          >
          </ProtectedRoute>
        </Switch>
      </div>
    )
}