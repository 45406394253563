const sendData = function(url, method, data, callback){
    let extras = {
        headers: new Headers({'Content-Type': 'application/json'}),
        method: method
    };
    if(method === 'POST'){
        extras.body = JSON.stringify(data);
    }
    
    fetch(url, extras)
    .then(response=>{
        return response.json();
    })
    .then(data=>{
        callback(data)
    })
    .catch(err=>{
        console.log("Send data>>>>>>"+ err.message);
    })
}

export {sendData}