// CardSection.js
import React from 'react';
import { CardElement } from 'react-stripe-elements';
import './CardSection.scss'
import { injectStripe } from 'react-stripe-elements'
function CardSection(props) {

  const checkCardValid = function(event){
    if (event.complete) {
      props.onCardUpdate(true);
    } else if (event.error) {
      props.onCardUpdate(false);
    }
  }
  
  
    return (
        <div className="stripe-container">
          <div className="label">Card details</div>
          <CardElement onChange = {(event)=>checkCardValid(event)}/>
        </div>
    );
  }



export default injectStripe(CardSection);