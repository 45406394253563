import React, {useState} from 'react';
import './App.scss';
import MainContainer from '../MainContainer/MainContainer';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  autoClose: 3000
});

function App(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSideBar = () => {
    setSidebarOpen(!sidebarOpen)
  };
  const backdropClickHandler = () => {
    setSidebarOpen(false);
  };
  return (
    <div className="app-container">
      { <Sidebar show={sidebarOpen} click={backdropClickHandler} /> }
      { <Header click={toggleSideBar}/> }
      <MainContainer />
    </div>
  );
}

export default App;
