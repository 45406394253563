import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import Footer from '../Footer/Footer';
import './HomeAndDevices.scss';
import UpgradeSubscriptionModal from '../UpgradeSubscriptionModal/UpgradeSubscriptionModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getDomainName from '../Utils/DomainName';
import { getCustomerData, getSubscriptionData, getHouseData } from '../DataCache/DataCache';
export default function HomeAndDevices(props){
  const [houses, setHouses] = useState([]);
  const [house, setHouse] = useState(false);
  const [customer, setCustomer] = useState({})
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [validSubscription, setValidSubscription] = useState(false);
  useEffect(()=>{
    fetchCustomer();
  },[]);
  
  function fetchCustomer() {
    getCustomerData({
      email: localStorage.email,
      onSuccess: customerData => {
        setCustomer(customerData);
        getHubAndHouse(customerData);
      },
      onFail: _ => {
        window.localStorage.clear();
        props.history.push('/login');
      }
    });
  }

  function getHubAndHouse(customerData, index) {
    getHouseData({
      ds_customer_id: customerData.ds_customer_id,
      onSuccess: data => {
        setHouse(data[0]);
        fetchSubscription(data[0]);
        let newHouses = [...data];
        newHouses.map((house, index)=>{
          house.status = ''
        });
        if(newHouses.length > 0) {
          newHouses[0].status = 'active';
          setHouses(newHouses); 
        }
        setHouseColor(0);
      },
      onFail: _ => {
        console.log('cant get house data');
      }
    });
  }
  
  function fetchSubscription(houseData) {
    getSubscriptionData({
      houseData: houseData,
      onSuccess: subscriptionObj => {
        setValidSubscription(subscriptionObj);
      },
      onFail: _ => {
        window.localStorage.clear();
        props.history.push('/login');
      }
    });
  }

  function addSubscription(data) {
    let domainName = getDomainName(window.location.hostname);
    let url = `https://${domainName}/ecommerce/api/admin_dashboard_v2/subscription/add_subscription`;
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
      method: 'POST',
      body: JSON.stringify(data)
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      fetchSubscription(house);
      toast('Successfully added subscription')
      setIsUpgrading(false);
    })
  }

  function setHouseColor(key) {
    let newHouses = [...houses];
    newHouses.map((house, index)=>{
      house.status = ''
    });
    if(newHouses.length > 0) {
      newHouses[key].status = 'active';
      setHouses(newHouses); 
    }
  }

  function renderHouseCircle(data, key) {
    return (
      <div key={key} className={`${houses[key].status} ` + "house-choice-container"} onClick={()=>{
          fetchSubscription(data);
          setHouse(data);
          setHouseColor(key);
        }}>
        <div className="house-choice" style={{ fontSize: (customer.type === 1 ? "26px" : "29px") }}>
          <ReactSVG
            beforeInjection={svg => {
              svg.classList.add('house-svg')
            }}
            src={require("../assets/home.svg")}
          />
        </div>
        <div className="house-name">{data.house.name && data.house.name.replace(' Home', '') || 'no name'}</div>
      </div>
    )
  }
  function renderHub(hub, key){
    return (
      <div className={"hub-container" + (key === 0 ? key : '')} key={key}>
          <ReactSVG  beforeInjection={svg => {
              svg.classList.add('hub-svg')
              svg.setAttribute('style', 'width: 55px')
            }}
            src={require("../assets/hub_line.svg")}
          />
          <div className="hub-info-container">
            <div className="hub-name">{hub.name.replace(' Home', '')}</div>
            <div className="hub-info-detail">Series No.: {hub.agent_version || '123'}</div>
            <div className="hub-info-detail">Cameras: {hub.camera_qty}</div>
          </div>
      </div>
    )
  }


  const onAddPlanBtn = function(data){
    if(data.house && data.house.total_hubs > 0){
      setIsUpgrading(true);
    }
    else{
      toast("No hubs found for the house, Please add devices to add a plan");
    }
  }
  function renderHubAndHouse() {
    if(!Object.keys(validSubscription).length > 0) {
      return (
        <div className="house-and-hub-info-container">
          <div className="plan-name">Loading...</div>
        </div>
      )
    }
    if(!(house && house.hubs && house.hubs.length > 0) && house.house && !validSubscription.valid_subscription) {
      return (
        <div className="house-and-hub-info-container">
          <div className="plan-name">{house.house.address_1} {house.house.address_2} {house.house.city}, {house.house.state}</div>
          <div style={{marginTop: '15px'}} className="info-detail">No devices detected. Add Cameras or Hubs using the Deep Sentinel app and they will be added to the subscription plan.</div>
        </div>
        )
    }
    if(house && !validSubscription.valid_subscription) {
      return (
        <div className="house-and-hub-info-container">
          <div className="plan-name">{house.house.address_1} {house.house.address_2} {house.house.city}, {house.house.state}</div>
          <div className="info-detail">&nbsp;</div>
          <div className="info-detail">No Subscription</div>
          <div className="info-detail">&nbsp;</div>
          <div className="action-button-container">
          <div className="action-button-container-content">
            <div className="upgrade-button" style={{ marginLeft: '0px'}} onClick={(event)=>{onAddPlanBtn(house)}}>Add Plan</div>
          </div>
        </div>
        <div className="hubs-container">
          {house.hubs.filter((hub)=> !hub.is_deleted).map((hub, key)=> {
            return (
              renderHub(hub, key)
              )
            })}
        </div>
      </div>
      )
    }
    if(house && validSubscription.valid_subscription) {
      return (
        <div className="house-and-hub-info-container">
          <div className="plan-name">{validSubscription.plan &&  validSubscription.plan.title}</div>
          <div className="info-detail">{house.house.address_1} {house.house.address_2} {house.house.city}, {house.house.state}</div>
          <div className="info-detail">Next Charge Amount: ${validSubscription.next_charge_amount / 100}</div>
          <div className="info-detail">Next Bill: {validSubscription.next_charge_date || ''}</div>
          <div className="hubs-container">
            {house.hubs.filter((hub)=> !hub.is_deleted).map((hub, key)=> {
              return (
                renderHub(hub, key)
                )
              })}
          </div>
          <div className="action-button-container">
            <div className="action-button-container-content">
              <div className="info-detail">Answers to many common subscription questions are on our <a style={{color: "black"}} target="_blank" href="https://deepsentinelsupport.zendesk.com/hc/en-us/categories/360001428174-Customer-Support">website.</a></div>
            </div>
          </div>
        </div>
      )
    }
  }
  return (
    <div className="hub-and-house-container">
        {
          houses.length > 0 && (
          <div className="content">
                <div className="house-choices">
              {houses.map((house, key)=>{
                return (
                  renderHouseCircle(house, key)
                  )
                })}
            </div>
            {renderHubAndHouse()}
            <Footer/>
          </div>
        )
      }
      {isUpgrading && <UpgradeSubscriptionModal hubs={house.hubs} house={house.house} addSubscription={addSubscription} close={()=>{setIsUpgrading(false)}} user={customer}/>}
      <ToastContainer style={{color: 'red !important'}} hideProgressBar={true} position={toast.POSITION.BOTTOM_CENTER} />
    </div>
  )
}