import React, {useEffect} from 'react';
import './Footer.scss';
import UseWindowDimensions from '../Utils/UseWindowDimensions';


export default function Footer(props) {
  const { width } = UseWindowDimensions();
  return (
    <div className="footer" style={{display: (props.hidden && width < 768  ? 'none' : 'block')}}><a href="https://www.deepsentinel.com" target="_blank">Deep Sentinel for Home</a> | <a href="https://www.deepsentinel.com/business/" target="_blank">Deep Sentinel for Business</a> | <a href="https://www.deepsentinel.com/support/" target="_blank">Support</a> | <a href="https://www.deepsentinel.com/privacy-policy/" target="_blank">Privacy Policy</a></div>
  )
}